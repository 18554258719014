import React from 'react';
import Home_Gallery from '../Home_Gallery/Home_Gallery';
import { Whatsapp } from '../Function/Function';

const Gallery = () => {
  return (
    <div>
      <Home_Gallery/>
    </div>
  );
}

export default Gallery;
