import React from 'react';
import Home_Products from '../Home_Products/Home_Products';

const Product = () => {
  return (
    <div>
      <Home_Products/>
    </div>
  );
}

export default Product;
